import { ToWords } from 'to-words';
import { DateTime } from 'luxon';
import { LOOM_TYPE_REVERSE_MAP, UNITS } from 'constants/globalConstant';

export const numWithCommas = (val, defaultVal = '') => {
  return val ? val.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',') : defaultVal;
};

export const dataSortOrder = (order, field, matchfield) => {
  if (field === matchfield) {
    switch (order) {
      case 'ascend':
        return 'asc';
      case 'descend':
        return 'desc';
      default:
        return '';
    }
  } else {
    return null;
  }
};

export const unitFunction = (incomingUnit, toShowUnit) => {
  switch (incomingUnit) {
    case UNITS.YARDS:
      return 'm';
    case UNITS.POUNDS:
      return 'kg';
    default:
      return toShowUnit;
  }
};

export const ANTD_TABLE_ORDER = {
  asc: 'ascend',
  desc: 'descend'
};

export const gstPattern = (gstNumber) => {
  return `${gstNumber.slice(0, 2)} ${gstNumber.slice(2, 12)} ${gstNumber.slice(12, 15)}`;
};

export function numberToWords(value, localeCode = 'en-IN') {
  const toWords = new ToWords({
    localeCode,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false
    }
  });
  return toWords.convert(value);
}

export const formateDateTime = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
  // Output -> DATE_MMMDDYYYYY
};

export const formatDate = (date) => {
  return date.format('YYYY-MM-DD HH:mm:ss').toString();
};

export const formatTime = (dateIso) => {
  return DateTime.fromISO(dateIso).toLocaleString(DateTime.TIME_SIMPLE);
  // Output like -> 09:30 AM
};

export const convertToUSD = (amount, conversionRate) => {
  const convertedPrice = (Number(amount) / Number(conversionRate)).toFixed(2);
  return convertedPrice;
};

export const valueChecker = (value, returnValue = '') => value ?? returnValue;

export const getQualitySpecifications = (inputs) => {
  const {
    threadcount,
    construction,
    width,
    weight,
    loomType,
    requirementType,
    denimQualtiySpecification
  } = inputs;
  let qualitySpecifications = '';
  if (requirementType === 6) return denimQualtiySpecification;
  if (threadcount) {
    qualitySpecifications += `${threadcount} / `;
  }

  if (construction) {
    qualitySpecifications += `${construction} / `;
  }

  if (width) {
    qualitySpecifications += `${width} inch / `;
  }

  if (weight) {
    qualitySpecifications += `${weight} GSM / `;
  }

  if (loomType) {
    qualitySpecifications += `${LOOM_TYPE_REVERSE_MAP[loomType]}`;
  }

  return qualitySpecifications;
};

export const windowReload = () => window.location.reload();

export const nounWord = (word, count) => {
  return Number(count) <= 1 ? String(word) : `${word}s`;
};

export const getLink = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data[keyword] === finder;
    })
    .pop();
};

export const getNameFromLink = (linkUrl) => {
  if (linkUrl) {
    const urlSpliter = linkUrl.split('/');
    return urlSpliter[urlSpliter.length - 1];
  }
  return null;
};

export const getLinkLfbModal = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data?.fileDetail?.title === finder;
    })
    .pop();
};

export const getLinkApproveForm = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data?.fileDetails?.title === finder;
    })
    .pop();
};

export const getFile = (files, titleName) => {
  let url = null;
  files.forEach((file) => {
    if (file.fileDetails.title === titleName) {
      url = file.fileDetails.url;
    }
  });
  return url;
};

const removeSpecialCharacter = (value) => value?.trim()?.replace(/[^a-zA-Z ]/g, '');

// check file type for File upload component
export const checkFileType = (uploadedFileType, accetableFileType) => {
  const matchingFileTypes = accetableFileType
    .split(',')
    .filter((fileType) => uploadedFileType.includes(removeSpecialCharacter(fileType)));
  return Boolean(matchingFileTypes.length);
};

export const computedQuantity = (quantity, unit) => {
  if (unit === UNITS.YARDS) {
    return (+quantity * 0.9144).toFixed(2);
  }
  if (unit === UNITS.POUNDS) {
    return (+quantity * 0.453592).toFixed(2);
  }
  return quantity;
};
