export const LOOM_TYPE_REVERSE_MAP = {
  1: 'Power Loom',
  2: 'Sulzer',
  3: 'Air Jet'
};

export const DELIVERY_TYPE_LANDED = 1;
export const DELIVERY_TYPE_X_FACTORY = 2;
export const DELIVERY_TYPE_CNF = 3;

export const DELIVERY_TYPE_REVERSE_MAP = {
  [DELIVERY_TYPE_LANDED]: 'Landed',
  [DELIVERY_TYPE_X_FACTORY]: 'x-Factory',
  [DELIVERY_TYPE_CNF]: 'CNF'
};

export const RUPEE = '₹';
export const DOLLAR = '$';

export const PRODUCTION_SUB_TYPE_REVERSE_MAP = {
  1: 'Greige',
  2: 'Yarn Dyed',
  3: 'RFD',
  4: 'Dyed',
  5: 'Printed',
  6: 'Denim'
};

export const PRODUCT_TYPE = {
  1: 'Running quality',
  2: 'Made to order'
};

export const HTTP_STATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  AMBIGUOUS: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  I_AM_A_TEAPOT: 418,
  UNPROCESSABLE_ENTITY: 422,
  FAILED_DEPENDENCY: 424,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505
};

export const UNITS = {
  KILOGRAMS: 1,
  METERS: 2,
  CENTIMETER: 3,
  INCH: 4,
  GRAMS: 5,
  PIECES: 6,
  GROSS: 7,
  YARDS: 8,
  POUNDS: 9
};

export const DEFAULT_FILE_SIZE = 5000000; // Bytes
